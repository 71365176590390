import React, { useEffect } from 'react'


import { useNavigate, useLocation } from 'react-router-dom'
import { use } from 'echarts'
import { useTranslation } from 'react-i18next'

import { SaveInfo } from '../store/modules/socializeConfigStore'
import { useSelector, useDispatch } from 'react-redux'
import { message } from 'antd'
import { Shop, Home, Eco, My } from '../assets/images/bottom'
// import { getSocializeConfigApi } from '../request/api'
function Menu ({ img, text, onClick, status }) {
  // console.log(onClick)
  return <div className='flex flex-col justify-center  ' onClick={onClick}>
    <div>
      {img}
      {/* <img className='w-[24px] h-[24px]' src={img} alt="" /> */}
    </div>
    <div className={`mt-1 ${status == true ? 'text-[#5767FF]' : 'text-[#5767FF]'}`}>{text}</div>
  </div>
}
export default function Bottom () {
  const nav = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const socializeInfo = useSelector((state) => state.socializeConfigStore)
  const clickHandler = (path) => {
    console.log(path)
    nav(path)
  }

  // const getSocializeConfig = async () => {
  //   try {
  //     const res = await getSocializeConfigApi()
  //     if (res.data.code === 200) {
  //       dispatch(SaveInfo(res.data.data))
  //     }

  //   } catch (error) {
  //     console.log(error)
  //   }


  // }
  useEffect(() => {
    // console.log(loaction)
    // getSocializeConfig()
  }, [])
  return (
    <div className='flex flex-col items-center w-full pt-4'>
      <div className=' fixed bottom-4 bg-[#ffffff]  w-[333px] h-[55px] rounded-full flex justify-around items-center'
        style={{
          border: '1px solid #5767FF',
          boxShadow: 'inset 2px -3px #5767FF',
        }}
      >
        {location.pathname == '/home' ?
          <Menu img={<Home />} text={t('home')} status={true} ></Menu>
          : <Menu img={<Home />} text={t('home')} onClick={() => { nav('/home') }}></Menu>}
        {location.pathname == '/shop' ?
          <Menu img={<Shop />} text={t('shoppingCentre')} status={true} ></Menu>
          : <Menu img={<Shop />} text={t('shoppingCentre')} onClick={() => { nav('/shop') }}></Menu>}
        {location.pathname == '/eco' ?
          <Menu img={<Eco />} text={t('ecology')} status={true} ></Menu>
          : <Menu img={<Eco />} text={t('ecology')} onClick={() => {
            nav('/eco')
            // message.info(t('notOpenYet'))
          }}></Menu>}
        {location.pathname == '/personalCenter' ?
          <Menu img={<My />} text={t('itSMine')} status={true} ></Menu>
          : <Menu img={<My />} text={t('itSMine')} onClick={() => { nav('/personalCenter') }}></Menu>}
      </div>
    </div >
  )
}

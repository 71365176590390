import React from 'react';

const My = (props) => {
	return (
		<svg height="20" width="20" fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
			<path d="M10.0025 13.3333C19.25 13.3333 19.1583 19.0583 19.1583 19.0583C19.2283 19.5783 18.8533 20 18.32 20H1.685C1.15166 20 0.75583 19.5725 0.846663 19.0583C0.846663 19.0583 0.753329 13.3333 10.0025 13.3333ZM10 0H15.8333V5.83333C15.8333 6.98706 15.4912 8.11487 14.8502 9.07416C14.2093 10.0334 13.2982 10.7811 12.2323 11.2226C11.1664 11.6641 9.99353 11.7797 8.86197 11.5546C7.73041 11.3295 6.69101 10.7739 5.87521 9.95812C5.0594 9.14232 4.50383 8.10292 4.27875 6.97136C4.05367 5.8398 4.16919 4.66692 4.6107 3.60101C5.05221 2.53511 5.79988 1.62407 6.75917 0.983094C7.71846 0.342119 8.84627 0 10 0Z" fill="#5767FF" />
		</svg>
	)
}

export default My

import React from 'react';

const Eco = (props) => {
	return (
		<svg height="21" width="25" fill="none" viewBox="0 0 25 21" xmlns="http://www.w3.org/2000/svg">
	<path d="M24.0846 6.66663C22.3388 6.66663 20.6317 6.86084 18.9243 7.24895C16.8291 7.71465 14.8117 8.41331 12.9884 9.61616C11.7856 10.3924 10.8544 11.3625 10.35 12.682C9.92349 13.7688 9.80696 14.8941 10.0785 16.136C10.35 15.7479 10.5829 15.4376 10.8156 15.2047C11.9796 13.7298 13.3374 12.4103 15.0833 11.634C15.5876 11.4398 16.1698 11.3235 16.7128 11.2459C17.1009 11.2071 17.4113 11.5177 17.45 11.9058C17.45 12.3327 17.2175 12.682 16.7906 12.7208C15.976 12.7599 15.3163 13.1477 14.6567 13.5746C12.9496 14.739 11.5916 16.1751 10.6606 18.0378C10.5054 18.3096 10.2726 18.7362 10.1175 19.6291C10.0788 19.7454 10.1563 19.9396 10.195 20.056C10.3113 20.3666 10.6994 20.5605 11.0097 20.4829C11.3588 20.4053 11.5918 19.823 11.8244 19.241C12.1734 19.4739 12.484 19.6679 12.8331 19.823C13.8808 20.289 15.0058 20.3666 16.131 20.2499C18.4974 19.9784 20.3989 18.9692 21.5626 16.7959C21.7566 16.369 22.0282 15.9421 22.1447 15.4764C22.5328 14.0794 22.8819 12.6435 23.2309 11.2462C23.5025 10.0818 23.8128 8.95627 24.6665 8.0637C24.9383 7.79192 25.0936 7.52017 24.9383 7.1323C24.783 6.74421 24.4727 6.66663 24.0846 6.66663ZM3.98755 19.2795C3.98755 19.8618 4.53066 20.3272 5.19031 20.3272H8.99258C8.99258 19.5897 9.18662 18.7747 9.53569 17.96H5.19031C4.53064 17.9603 3.98755 18.426 3.98755 19.008V19.2795ZM9.10884 15.787C8.83728 14.4675 8.9148 13.2253 9.3804 12.0224C9.53567 11.6343 9.72944 11.2462 9.96252 10.8969L10.9712 9.77102C11.3206 9.42197 11.7471 9.11111 12.2127 8.80085C12.6393 8.5291 13.0661 8.29639 13.493 8.0634L14.5017 7.52017C15.6269 7.0157 16.8684 6.62759 18.304 6.31702C19.3902 6.08403 20.5154 5.92891 21.6016 5.81255V1.97461C21.6016 1.15959 20.981 0.5 20.1664 0.5H2.41179C1.59714 0.5 0.9375 1.15959 0.9375 1.97461V14.8553C0.9375 15.6316 1.59714 16.2912 2.41179 16.2912H9.2251L9.10884 15.787Z" fill="#5767FF"/>
</svg>
	)
}

export default Eco

import React from 'react';

const Shop = (props) => {
	return (
		<svg height="20" width="20" fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_63_287)">
				<path d="M1.37524 10.3965H18.625V19.9532C18.625 19.9656 18.6201 19.9775 18.6113 19.9863C18.6025 19.9951 18.5906 20 18.5782 20H1.42203C1.40962 20 1.39772 19.9951 1.38895 19.9863C1.38017 19.9775 1.37524 19.9656 1.37524 19.9532V10.3965ZM18.3019 1.07039C18.1337 0.525017 17.7638 0 17.1087 0H2.89157C2.23647 0 1.87101 0.517998 1.69835 1.07039L0.505127 6.70028C0.505127 7.96369 1.33079 9.06449 2.52237 9.58717C3.29586 9.92665 4.2261 9.78978 4.84938 9.25658L6.19468 8.10687C6.52551 7.82401 7.03695 7.79874 7.3996 8.04768L9.22733 9.29963C9.75539 9.6611 10.4879 9.66017 11.0146 9.29705L12.7338 8.11202C13.1177 7.84741 13.6612 7.87969 14.0028 8.18759L15.4239 9.46902C15.7837 9.79353 16.3101 9.92595 16.8043 9.81154C18.3466 9.45475 19.4949 8.19251 19.4949 6.70145L18.3019 1.07039ZM16.3534 4.26985H3.6468V2.87636H16.3534V4.26985Z" fill="#5767FF" />
			</g>
			<defs>
				<clipPath id="clip0_63_287">
					<rect height="20" width="20" fill="white" />
				</clipPath>
			</defs>
		</svg>
	)
}

export default Shop

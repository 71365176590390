
/*eslint-disable*/
import request from './request'

//提交绑定地址
// export const BindRelationshipApi = (params) => request.post('/user/bindRelationship', params)



//首页公告
export const getHomeNoticeApi = (params) => request.get('/notice/get_notice', { params })


//公告列表
export const getNoticeListApi = (params) => request.get('/notice/get_notice_list', { params })

//1.获取用户是否存在
export const PostExistUserApi = (params) => request.post('/user/exist_user', params)

//签名
export const PostSignApi = (params) => request.post('/signed/check_signed', params)
// //激活用户
export const PostSetRefApi = (params) => request.post('/user/bind', params)
//个人中心数据
export const getUserInfoApi = (params) => request.get('/user/user_info', { params })
//轮播图
export const getBannerApi = (params) => request.get('/banner/get_banner', { params })

//提现操作
export const PostWithdrawApi = (params) => request.post('/withdraw_record/withdraw', params)

//获取资产
export const getAssetsInfoApi = (params) => request.get('/user/user_info', { params })

//闪兑操作
export const PostExchangeApi = (params) => request.post('/exchange_record/exchange', params)


//获取AI金融配置
export const getAIRobotConfigApi = (params) => request.get('/finance/get_finance_config', { params })

// //AI金融投资操作
// export const PostFinanceOperateApi = (params) => request.post('/finance/finance_operate', params)


//我的AI机器人列表
export const getAIRobotListApi = (params) => request.get('/finance/get_finance_list', { params })

//AI金融推荐奖
export const getRecommendationAwardApi = (params) => request.get('/finance/get_recommendation_award', { params })

//获取推荐人福利
export const getReferrerBenefitsApi = (params) => request.get('/finance/get_referrer_benefits', { params })

//获取AI订单详情
export const getFinanceDetailsApi = (params) => request.get('/finance/get_finance_details', { params })

//获取NFT配置
export const getNFTConfigApi = (params) => request.get('/nft/get_nft_config', { params })

//获取NFT记录列表
export const getNFTRecordApi = (params) => request.get('/nft/nft_record', { params })

//获取NFT挖矿详情
export const getNFTDetailApi = (params) => request.get('/nft/get_nft_details', { params })

//社区奖励列表
export const getNftCommunityAwardApi = (params) => request.get('/nft/get_nft_community_award', { params })

//我的机器人数据
export const getMyFinanceDataApi = (params) => request.get('/finance/my_finance_data', { params })

//我的节点数据
export const getMyNodeApi = (params) => request.get('/user/my_node', { params })

//资金明细列表
export const getFundDetailApi = (params) => request.get('/record/get_money_detail', { params })


//质押操作
export const PostPledgeOperationApi = (params) => request.post('/pledge/pledge_operation', params)

//我的质押社区
// export const getMyCommunityApi = (params) => request.get('/pledge/my_community', { params })


//质押订单收益
export const getOrderIncomeApi = (params) => request.get('/pledge/order_income', { params })

//质押直推收益列表
export const getDirectPushIncomeApi = (params) => request.get('/pledge/direct_push_income', { params })

//质押团队收益
export const getTeamIncomeApi = (params) => request.get('/pledge/team_income', { params })

//质押直推用户列表
export const getDirectPushListApi = (params) => request.get('/pledge/direct_push_list', { params })

//销毁接口
export const getDestroyListApi = (params) => request.get('/pledge/destroy_list', { params })

//私募质押配置
export const getStakingConfigApi = (params) => request.get('/pledge/get_pledge_config', { params })

//USDT详情
export const getUSDTDetailApi = (params) => request.get('/record/get_usdt_detail', { params })

//FDV详情
export const getFDVDetailApi = (params) => request.get('/record/get_fvd_detail', { params })

//SNB详情
export const getSNBDetailApi = (params) => request.get('/record/get_snb_detail', { params })



//获取私募数据
export const getMyCoCreateApi = (params) => request.get('/private/my_private_placement', { params })

//我的NFT分红
export const getMyNftDataApi = (params) => request.get('/nft/my_nft', { params })

//我的社区
// export const getMYCommunityApi = (params) => request.get('/pledge/my_community', { params })

//社区总奖励
export const getComTeamIncomeApi = (params) => request.get('/record/get_team_income', { params })

//直推收益
export const getRecommendIncomeApi = (params) => request.get('/record/get_recommend_income', { params })

//直推人数 
export const getDirectDetailApi = (params) => request.get('/pledge/direct_push_list', { params })

//我的AI量化订单
export const getMyOrderApi = (params) => request.get('/ai/get_ai_order', { params })


//赎回本金
export const PostRetrievePrincipalApi = (params) => request.post('/pledge/retrieve_principal', params)

//提取利息
export const PostRetrieveInterestApi = (params) => request.post('/pledge/retrieve_interest', params)

//团队收益列表
export const getAiTeamIncomeApi = (params) => request.get('/ai/get_ai_team_income', { params })

//静态收益列表
export const getAiIncomeApi = (params) => request.get('/ai/get_ai_income', { params })

//直推收益列表
export const getAiDirectIncomeApi = (params) => request.get('/ai/get_ai_direct_income', { params })

//我的团队
export const getAiTeamApi = (params) => request.get('/ai/get_ai_team', { params })

//币价图标
export const getIconDataApi = (params) => request.get('/currency_value/get_icon_data', { params })

//闪兑
export const PostexchangeApi = (params) => request.post('/user/exchange', params)

//直推列表
export const getAiDirectPerformanceApi = (params) => request.get('/ai/get_ai_direct_performance', { params })


//c2c买单列表
export const getBuyOrderListApi = (params) => request.get('/c2c/buy_order_list', { params })

//c2c创建买单
export const PostCreateBuyOrderApi = (params) => request.post('/c2c/create_buy_order', params)

//我的挂买单列表

export const getMyBuyOrderListApi = (params) => request.get('/c2c/my_buy_order_list', { params })

//撤销买单
export const PostBuyOrderWithdrawApi = (params) => request.post('/c2c/buy_order_withdraw', params)

//卖出
export const PostBuyOrderTradeApi = (params) => request.post('/c2c/buy_order_trade', params)

//卖出详情（买单交易详情）
export const getBuyOrderDetailsApi = (params) => request.get('/c2c/buy_order_details', { params })

//游戏卡牌配置
export const getGameCardConfigApi = (params) => request.get('/destroy/game_card_config', { params })

//游戏卡牌下拉列表
export const gameCardConfigListApi = (params) => request.get('/destroy/game_card_config_list', { params })

//我的卡牌列表
export const getMyGameCardListApi = (params) => request.get('/destroy/my_game_card_list', { params })

//我的NFT节点
export const getMyNftNodeListApi = (params) => request.get('/nft/my_nft_node_list', { params })

//OMNI详情
export const getOmnDetailApi = (params) => request.get('/record/get_omn_detail', { params })

//我的社区
export const getMyCommunityApi = (params) => request.get('/user/my_community', { params })

//卡牌游戏收益详情
export const getCardIncomeDetailApi = (params) => request.get('/record/get_card_income_detail', { params })

//节点收益明细
export const getNftIncomeDetailApi = (params) => request.get('/record/get_nft_income_detail', { params })

//节点分红明细
export const getNftBonusDetailApi = (params) => request.get('/record/get_nft_bonus_detail', { params })

//游戏卡牌直推收益
export const getGameCardIncomeDetailApi = (params) => request.get('/record/get_game_card_income_detail', { params })

//游戏卡牌团队收益
export const getGameCardTeamIncomeDetailApi = (params) => request.get('/record/get_game_card_team_income_detail', { params })

//生态币
export const getEcologyDetailApi = (params) => request.get('/record/get_ecology_detail', { params })

//我的直推
export const getMyDirectListApi = (params) => request.get('/user/get_direct_address', { params })

//反馈列表
export const getProposalListApi = (params) => request.get('/user/get_proposal_list', { params })

//反馈详情
export const getProposaDetailApi = (params) => request.get('/user/get_proposal_details', { params })


//上传图片
export const postUploadApi = (params) => request.post('/user/upload', params, { headers: { 'Content-Type': 'multipart/form-data' } })

//提案操作
export const postProposalOperateApi = (params) => request.post('/user/proposal', params)

//商品列表
export const getGoodsListApi = (params) => request.get('/user/get_goods_list', { params })

//商品详情
export const getGoodsDetailApi = (params) => request.get('/user/get_goods_details', { params })

//提案反馈
// export const postChatroomCommentApi = (params) => request.post('/user/proposal', params)

//上传商品
export const postUploadGoodsApi = (params) => request.post('/shop/upload_goods', params, { headers: { 'Content-Type': 'multipart/form-data' } })

//获取地区列表
export const getRegionListApi = (params) => request.get('/user/get_region_list', { params })

//创建地址
export const postCreateDeliveryAddressApi = (params) => request.post('/delivery_address/create_delivery_address', params)

//获取地址列表
export const getDeliveryAddressListApi = (params) => request.get('/delivery_address/get_delivery_address_list', { params })

//删除地址
export const postDeleteDeliveryAddressApi = (params) => request.post('/delivery_address/delete_delivery_address', params)

//更新地址
export const postUpdateDeliveryAddressApi = (params) => request.post('/delivery_address/update_delivery_address', params)

//获取地址详情
export const getDeliveryAddressDetailsApi = (params) => request.get('/delivery_address/get_delivery_address_details', { params })


//取回店铺质押
export const postRetrievePledgeApi = (params) => request.post('/shop/retrieve_pledge', params)

//申请开店
export const postApplyShopApi = (params) => request.post('/shop/create_shop', params)

//店铺分类
export const getShopCategoryApi = (params) => request.get('user/get_shop_category', { params })

//设置默认地址
export const postSetDefaultAddressApi = (params) => request.post('delivery_address/set_default_delivery_address', params)

//我的订单列表
export const getMyOrderListApi = (params) => request.get('shop/user_order', { params })

//订单详情
export const getOrderDetailsApi = (params) => request.get('shop/get_order_details', { params })

//我的商品
export const getMyGoodsListApi = (params) => request.get('shop/my_goods', { params })

//让利订单列表
export const getProfitSharingOrdersApi = (params) => request.get('shop/rebate_order_list', { params })

//领取让利订单收益
export const postCollectProfitSharingApi = (params) => request.post('shop/receive_rebate_income', params)

//下架商品
export const postDownGoodsApi = (params) => request.post('shop/off_sale_goods', params)

//店铺列表
export const getStoreListApi = (params) => request.get('shop/get_shop_list', { params })

//店铺信息
export const getStoreInfoApi = (params) => request.get('/shop/get_shop_info', { params })


//商家我的订单
export const getMerchantOrderListApi = (params) => request.get('/shop/shop_my_order', { params })

//商家发货
export const postSendGoodsApi = (params) => request.post('/shop/send_goods', params)

//物流列表
export const getExpressListApi = (params) => request.get('/shop/get_express_list', { params })

//确认收货
export const postConfirmReceiptApi = (params) => request.post('/shop/confirm_receipt', params)

//代币明细
export const getCoinDetailApi = (params) => request.get('/record/get_coin_detail', { params })


//NFT分红明细
export const getNftProfitDetailApi = (params) => request.get('/record/get_nft_detail', { params })


//编辑商品
export const postEditGoodsApi = (params) => request.post('/shop/edit_goods', params)

import React from 'react';

const Home = (props) => {
	return (
		<svg height="21" width="21" fill="none" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
			<path d="M11.563 0.880731L19.2016 6.36328C19.571 6.62847 19.8721 6.97783 20.0797 7.38244C20.2874 7.78706 20.3958 8.23531 20.3958 8.69011V17.5313C20.3958 19.1133 19.1133 20.3958 17.5312 20.3958H13.625V16.2292C13.625 14.5034 12.2258 13.1042 10.5 13.1042C8.77422 13.1042 7.375 14.5034 7.375 16.2292V20.3958H3.46875C1.88672 20.3958 0.604167 19.1133 0.604167 17.5313V8.69037C0.604186 8.23552 0.712516 7.78722 0.920192 7.38256C1.12787 6.97789 1.42892 6.6285 1.79844 6.36328L9.43698 0.880731C9.74681 0.658322 10.1186 0.538696 10.5 0.538696C10.8814 0.538696 11.2532 0.658322 11.563 0.880731Z" fill="#5767FF" />
		</svg>
	)
}

export default Home
